import * as $ from 'jquery';


export default (function () {

    $(document).on('submit', 'form.submit_ajax', function (e) {
        e.preventDefault();
        const form = $(this);
        const modal = $(this).parents('.modal-content');

        handleLoading(modal);
        const formData = new FormData(form[0]);
        const url = form.attr('action');

        $.ajax({
            method: 'POST',
            url: url,
            processData: false,
            contentType: false,
            data: formData,
            error: function (reject) {
                handleLoading(modal,true);
                if (reject.status === 422) {
                    const errors = $.parseJSON(reject.responseText);
                    const allError = errors.errors;

                    let html = '<div class="ajax_error alert alert-danger">';
                    $.each(allError, function (key, val) {
                        $.each(val ,function (k,v) {
                            html += '<p style="margin:0">'+v+'</p>';
                        })
                    });
                    html += '</div>';
                    modal.find('.modal-body').find('.ajax_error').remove();
                    modal.find('.modal-body').prepend(html);
                } else {
                    handleOtherError(modal);
                }
            },
            success: function (response) {
                //nếu thành công thì redirect về return url trong kêt quả
                //nếu thất bại thì in ra lỗi
                response = $.parseJSON(response);

                if(response.status === 'success'){
                    window.location = response.return_url;
                } else if (response.status === 'api-unauthenticated') {
                    if(response.hasOwnProperty('url_logout')) window.location.href = response.url_logout;
                    window.location.href = '/logout';
                } else {
                    handleLoading(modal,true);
                    if (response.status === 'error'){
                        modal.find('.modal-body').find('.ajax_error').remove();
                        modal.find('.modal-body').prepend('<div class="ajax_error alert alert-danger">'+response.msg+'</div>');
                    } else {
                        handleOtherError(modal);
                    }
                }
            }
        });
    });

    function handleLoading(modal,off=false) {
        //thêm loading + mờ nền
        let htmlLoading = '<div class="gif_loading">';
        htmlLoading += '<img src="/images/loading_70px.gif" alt="">';
        htmlLoading += '</div>';
        if (off){
            modal.find('.gif_loading').remove();
            modal.find('.modal-body').css('opacity','1');
        } else {
            modal.append(htmlLoading);
            modal.find('.modal-body').css('opacity','0.5');
        }
    }

    function handleOtherError(modal) {
        modal.find('.modal-body').find('.ajax_error').remove();
        modal.find('.modal-body').prepend('<div class="ajax_error alert alert-danger">Có lỗi xảy ra , vui lòng thử lại !</div>');
    }

}());
