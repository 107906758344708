import * as $ from 'jquery';


export default (function () {
    $( "button.navbar-toggler" ).remove();
    //js tổng hợp

    $("input[placeholder]").each(function () {
        $(this).attr('size', $(this).attr('placeholder').length);
    });

    $('select.select_submit').change(function () {
        var form = $(this).parents('form');
        form.submit();
    });

    // if ($(document).height() > $(window).height()) {
    //     $('.footer').css('position', 'relative');
    // }
    //
    // $(window).on('resize', function () {
    //     $('.footer').css('position', 'relative');
    // });

    //ấn vào kính núp ở các ô  input keyword
    // $('.btn-search-keyword').click(function () {
    //     let valueKeyword = $(this).parents('form').find('input[name="keyword"]').val();
    //     if(valueKeyword != ''){
    //         $(this).parents('form').submit();
    //     }
    // });

    $('.btn-search-keyword').click(function () {
        let keywordSearch = $('.keywordSearch').val();
        if(keywordSearch.length > 0) {
            window.location.href = $('.search').data('href') + `?keyword=${keywordSearch}`;
        } else {
            window.location.href = $('.search').data('href');
        }
    });

    // $('input[name="keyword"]').keypress(function(e) {
    //     if (e.which == 13) {
    //         e.preventDefault();
    //         let value = $(this).val();
    //         if (value != '') {
    //             $(this).parents('form').submit();
    //         }
    //     }
    // }

    $('input.keywordSearch').keypress(function(e) {
        if (e.which == 13) {
            e.preventDefault();
            let keywordSearch = $('.keywordSearch').val();
            if(keywordSearch.length > 0) {
                window.location.href = $('.search').data('href') + `?keyword=${keywordSearch}`;
            } else {
                window.location.href = $('.search').data('href');
            }
        }
    });

    //ấn show modal và tắt hết modal khác
    $('.modal-open-and-close').click(function () {
        $('.modal').modal('hide');
    })

    $('.date-picker-created').datepicker({
        orientation: "top auto",
        autoclose: true,
        format: "dd-mm-yyyy"
    });

    $('.clear-date-picker-created').click(function () {
        $('.date-picker-created').val('');
        $(this).parents('form').submit();
    });


//    js cho module credential

    $('#modal_create_api_key button[type="submit"]').click(function (e) {
        e.preventDefault();
        let name_service = $('.container-credential .service_credential .list_credential').find('a.active').attr('data-slug');
        let html = '<input type="hidden" name="name_api_key" value="' + name_service + '">';
        $(this).parents('#modal_create_api_key').find('form').append(html);
        $(this).parents('#modal_create_api_key').find('form').submit();
    })

    $('.add_more_white_list').click(function () {
        let html = '<div class="item_white_list">';
        html += '<span class="ti-close close_white_list"></span>';
        html += ' <input type="text" name="white_list[]" class="form-control" required> ';
        html += '</div>';
        $(this).parents('.create_edit_restriction').find('.white_list_group').append(html);
    })

    $(document).on("click", ".close_white_list", function (e) {
        $(this).parent().remove();
    });


//    home page
    $('.toggle_category_service').click(function () {
        let element = $(this).parents('.wrapper_category_service').find('.item');
        if (element.css('display') == 'none') {
            $(this).find('span').removeClass('ti-angle-up').addClass('ti-angle-down');
        } else {
            $(this).find('span').removeClass('ti-angle-down').addClass('ti-angle-up');
        }
        element.fadeToggle("fast");
    })


    $('.page-container').click(function () {
            if (window.innerWidth <= 1439 && $( "body" ).hasClass( "is-collapsed" )) {
            $( "body" ).removeClass( "is-collapsed" )
        }
    })

    $('.ajax-content').delegate('li', 'click' , function () {
        if($('.ajax-content .nav-link.active').attr('href') == '#info') {
            $(".ajax-content #info").css("display", "none");
            $(".ajax-content #users").css("display", "block");
        }
        if($('.ajax-content .nav-link.active').attr('href') == '#users') {
            $(".ajax-content #info").css("display", "block");
            $(".ajax-content #users").css("display", "none");
        }

    })

}());
