import 'bootstrap';
window._ = require('lodash');

import './../plugins/bootstrap-datepicker/js/bootstrap-datepicker';
import './../plugins/masonry/index';
import './../plugins/select2/select2.min';

import './sidebar';
import './scrollbar';

import './custom/other';
import './custom/modal_ajax';
import './custom/submit_form';
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


// window.Vue = require('vue');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Vue.component('example-component', require('./components/ExampleComponent.vue'));

// const app = new Vue({
//     el: '#app'
// });
