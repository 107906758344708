import * as $ from 'jquery';


export default (function () {

    //--------------------------------------------------------------------------------------------------------------------------------------

    // đặt tên class cho thẻ a ấn xem detail là detail_show_modal , đặt href là url để gọi ajax đến lấy dữ liệu
    // url mà gọi ajax đến thì phải trả về theo dạng json với định dạng : $result = ['status' => 'error hoặc success']
    // nếu success thì trả thêm ở mảng result 1 key là data , key data chứ giá trị detail , ví dụ $result = ['status' => 'Success', 'data' => 'dữ liệu theo dạng mảng']
    // xem ví dụ về cách đặt tên html trong modal + cách kết quả trả về ở url ajax theo module content service

    let listUserCustom = [];
    //khi ấn mở các modal create , delele , edit thì phải xóa div alert thông báo
    function removeAlert() {
        $('.session_messages').remove();
    }


    $('.action_with_remove_alert').click(function () {
        removeAlert();
    });

    //modal delete
    $('.delete_show_modal').click(function (e) {
        e.preventDefault();
        removeAlert();
        let url = $(this).attr('href');
        let modal = $(this).parents('body.app').find('#modal_delete_resoure');
        let msg = $(this).attr('data-message');
        if (msg) {
            modal.find('.modal-body').html(msg);
        }
        modal.find('form').attr('action', url);
        modal.modal('show');
    });

    //modal detail
    $('.detail_show_modal').click(function (e) {
        e.preventDefault();
        showDetail($(this));
    });

    //modal edit
    $('.edit_show_modal').click(function (e) {
        e.preventDefault();

        showDetail($(this), true);
    });

    function handlingModal(modal) {
        modal.find('.gif_loading').remove();
        modal.find('.modal-body .content').hide();
        modal.find('.text-response-error').remove();
        modal.find('.modal-body').append('<p class="text-danger text-center text-response-error">Có lỗi , vui lòng thử lại !!</p>');
        modal.find('.modal-footer').remove();
        modal.find('.modal-body').css('opacity', '1');
    }

    function showDetail(element, update = false) {
        removeAlert();

        let url = element.attr('href');
        let prefix = element.attr('class').split('_')[0];
        let modal = '#' + 'modal_' + prefix;

        modal = element.parents('.container-fluid').find(modal);

        //mới ấn , sẽ ẩn content , xóa error , show loading
        modal.find('.text-response-error').remove();
        modal.find('.modal-body .content').hide();

        let htmlLoading = '<div class="gif_loading">';
        htmlLoading += '<img src="/images/loading_70px.gif" alt="">';
        htmlLoading += '</div>';
        modal.find('.modal-content').append(htmlLoading);

        modal.modal('show');
        $('.action_from_modal').hide();
        $('.disable-btn').attr('disabled','disabled');
        // modal.find('.modal-body .content').show();
        // modal.find('.modal-body').css('opacity','0.5');


        //nếu là update thì thay action của form
        if (update) {
            let urlUpdate = element.attr('data-href');
            modal.find('form').attr('action', urlUpdate);
        } else {
            //gắn sự kiện cho nút update_from_detail khi đang ở modal detail
            $('.update_from_detail').click(function () {
                element.parents('tr').find('.edit_show_modal').trigger('click');
            })
        }

        setTimeout(function () {
            //gọi ajax lấy dữ liệu để show
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                error: function () {
                    handlingModal(modal);
                }
            }).done(function (response) {
                if (response.hasOwnProperty('status')) {
                    if (response.status === 'success') {
                        if (response.hasOwnProperty('data')) {
                            //kiểm tra nếu ấn vào nút có làm hành động gì khác ko
                            let otherAction = element.attr('data-other-action');

                            //kiểm tra nếu sau khi hiện ra detail , có muốn làm thêm gì ko
                            let moreAction = element.attr('data-more-action');

                            if (otherAction) {
                                handleOtherAction(otherAction, element, prefix, modal, response.data, update)
                            } else {
                                $.each(response.data, function (index, value) {
                                    let element = '#' + prefix + '_' + index;
                                    if (update) {
                                        modal.find(element).val(value);
                                    } else {
                                        modal.find(element).html(value);
                                    }
                                });
                                if (moreAction) {
                                    handleMoreAction(moreAction, element, prefix, modal, response.data, update)
                                }
                            }

                            //nếu tồn tại data-url-delete từ nút ấn xem detail , thì gắn url cho modal delete
                            let dataUrlDelete = element.attr('data-url-delete');
                            if (dataUrlDelete) {
                                modal.find('.delete_show_modal').attr('href', dataUrlDelete);
                            }

                            modal.find('.gif_loading').remove();
                            modal.find('.text-response-error').remove();
                            modal.find('.modal-body .content').show();
                            modal.find('.modal-body').css('opacity', '1');

                            //nếu là cập nhật , chạy function ở submit_form.js để xử lý xem giá trị form có thay đổi ko để submit gọi api
                            // if (update) {
                            //     // gửi giá trị form + form
                            //     let formAjax = modal.find('form.submit_ajax');
                            //     let valueFormAjax = formAjax.serialize();
                            //     handleFormBeforeSubmit(formAjax, valueFormAjax);
                            // }

                            return;
                        }
                    } else if (response.status === 'api-unauthenticated') {
                        if (response.hasOwnProperty('url_logout')) window.location.href = response.url_logout;
                        window.location.href = '/logout';
                    }
                }
                handlingModal(modal);
            })
        }, 200);

    }

    function handleMoreAction(action, element, prefix, modal, response, update) {
        listUserCustom = [];
        $('.search-email-user-input').val('')
        switch (action) {
            case 'detail-service':
                //nút detail và edit dùng chung chỗ này
                $('.custom-warning-search-email').html('');
                $('#search-email-user').val('');

                const parent = modal.find('.list_user_access_service');
                let listUser = null;

                let listUserOfService = response.users;
                let infoAdmin = response.administrator;

                //gọi lấy list user
                $.ajax({
                    async: false,
                    url: '/services/users',
                    type: 'GET',
                    dataType: 'json',
                    error: function (reject) {
                        listUser = null;
                    },
                    success: function (response) {
                        if (response.status === 'success') {
                            listUser = response.data;
                        }
                    }
                });
                listUserCustom = listUserOfService;

                let result = false;
                //nếu update == false thì đây là detail , hiện danh sách user service
                if (listUserOfService != null) {
                    // đầu tiên là in ra danh sách những người có quyền
                    parent.find('ul.list_user_selected').html('').css('display', 'block');
                    $.each(listUserOfService, function (index, value) {
                        let htmlListUserOfService = '<li>';
                        //nếu là super admin , ko có dấu X
                        // if (value.id === infoAdmin.id) {
                        //     htmlListUserOfService += ' <span class="span_email">' + value.email + '</span>';
                        // } else {
                        //     htmlListUserOfService += ' <span class="span_email">' + value.email + '</span>';
                        //     if (update) {
                        //         htmlListUserOfService += '<span class="span_x"><i class="fa fa-lg fa-times-circle" aria-hidden="true"></i></span>';
                        //     }
                        // }
                        htmlListUserOfService += ' <span class="span_email">' + value.email + '</span>';
                        if (update) {
                            htmlListUserOfService += '<span class="span_x"><i class="fa fa-lg fa-times-circle" aria-hidden="true"></i></span>';
                        }
                        htmlListUserOfService += ' <input type="hidden" name="user_ids[]" value="' + value.id + '" class="input_id_user">';
                        htmlListUserOfService += ' </li>';
                        parent.find('ul.list_user_selected').append(htmlListUserOfService);
                    });
                    result = true;

                    if (update) {
                        if (listUser != null) {

                            //hiện select2 để chọn admin
                            let parentSelectAdmin = modal.find('.wrapper_select_admin');
                            parentSelectAdmin.html('');
                            let htmlSelectAdmin = '<select class="select_admin" name="admin_id">';
                            htmlSelectAdmin += '<option></option>';

                            //nếu update thì hiện thêm select2 để chọn những người có quyền
                            parent.find('.wrapper_add_more_user .wrapper_user_select2').html('');

                            let htmlListUser = '<select class="more_user_select2">';
                            htmlListUser += '<option></option>';
                            $.each(listUser, function (index, value) {
                                // kiểm tra xem nếu email đó đã tồn tại ở danh sách những người có quyền thì ko hiện ra nữa
                                let checkUserExist = _.findIndex(listUserOfService, function (o) {
                                    return o.email == value.email;
                                });

                                if (checkUserExist == '-1') {
                                    htmlListUser += ' <option value="' + value.id + '">' + value.email + '</option>';
                                }

                                htmlSelectAdmin += ' <option value="' + value.id + '">' + value.email + '</option>';

                            });
                            htmlListUser += '</select>';
                            htmlSelectAdmin += '</select>';
                            parent.find('.wrapper_add_more_user .wrapper_user_select2').append(htmlListUser);

                            parentSelectAdmin.append(htmlSelectAdmin);


                            $(document).ready(function () {
                                $(".more_user_select2").select2({
                                    placeholder: "Chọn người muốn cấp quyền",
                                });

                                $(".select_admin").select2({
                                    placeholder: "Chọn người muốn cấp quyền admin",
                                });
                                $(".select_admin").select2().val(infoAdmin.id).trigger('change.select2');

                                //khi click chọn 1 user ở trong select2
                                $('.more_user_select2').on('select2:select', function (e) {
                                    // lấy id + email của người được chọn
                                    let idUser = $(this).val();
                                    let emailUser = $(this).find(":selected").html();

                                    // append vào đầu list user
                                    let html = '<li>';
                                    html += '<span class="span_email">' + emailUser + '</span>';
                                    html += '<span class="span_x"><i class="fa fa-lg fa-times-circle" aria-hidden="true"></i></span>';
                                    html += '<input type="hidden" name="user_ids[]" value="' + idUser + '">';
                                    html += '</li>';

                                    parent.find('ul.list_user_selected').prepend(html);

                                    //    xóa cái vừa select ra khỏi select
                                    parent.find('.more_user_select2 option[value="' + idUser + '"]').remove()
                                });


                                //    khi ấn dấu x thì hiện lại user vào select
                                parent.on("click", ".span_x", function () {
                                    let parentLi = $(this).parents('li');
                                    let idOfUser = parentLi.find('.input_id_user').val();
                                    let emailOfUser = parentLi.find('.span_email').html();

                                    let htmlPrepend = '<option value="' + idOfUser + '">' + emailOfUser + '</option>';

                                    parent.find('.more_user_select2').prepend(htmlPrepend);

                                    parentLi.remove();
                                });

                            });
                        } else {
                            result = false;
                        }
                    }
                }

                if (result) {
                    //gắn link cho nút active
                    let urlAction = element.attr('data-url-action');

                    let titleAction = '';
                    let contentAction = '';
                    $('.action_from_modal').show();
                    $('.disable-btn').removeAttr('disabled');
                    if (response.activated_at) {
                        $('.action_from_modal').html('Bỏ kích hoạt');
                        $('.action_from_modal').addClass('btn-danger');
                        $('.action_from_modal').removeClass('btn-success');
                        titleAction = 'Bỏ kích hoạt dịch vụ';
                        contentAction = 'Bạn có chắc muốn bỏ kích hoạt dịch vụ không ?';
                    } else {
                        $('.action_from_modal').html('Kích hoạt');
                        $('.action_from_modal').addClass('btn-success');
                        $('.action_from_modal').removeClass('btn-danger');
                        titleAction = 'Kích hoạt dịch vụ';
                        contentAction = 'Bạn có chắc muốn kích hoạt dịch vụ không ?';
                    }

                    $(document).on('click', '.action_from_modal', function (e) {
                        let modalAction = $('#modal_action_service');
                        modalAction.find('.modal-title').html(titleAction);
                        modalAction.find('.modal-body').html(contentAction);
                        modalAction.find('form').attr('action', urlAction);
                        modalAction.modal('show');
                    });

                    parent.show();
                } else {
                    modal.find('.modal-body .content .error_get_user').show();
                }

                break;
            case 'detail-credential':
                let listRestriction = response.restrictions;

                if (update) {
                    const list_restriction = $('#list_restriction');

                    if (!Array.isArray(listRestriction)) {

                        $.each(listRestriction, function (keyParentRestriction, valueParentRestriction) {
                            //xóa hết element trước khi append cái mới vào
                            //tránh case lắy detail xong tắt modal xong lại ấn detail , ko bị nhân nhiều lần dữ liệu
                            list_restriction.find('#' + keyParentRestriction).find('.item_restriction .item_each').not('.btn_add_more_item').remove();

                            $.each(valueParentRestriction, function (key, value) {
                                let eachRestriction = '<div class="item_each">' + value;
                                eachRestriction += '<input type="hidden" value="' + value + '" name="' + keyParentRestriction + '[]" >';
                                eachRestriction += '<span class="remove_restriction pull-right"><i class="fa fa-lg fa-times-circle" aria-hidden="true"></i></span>';
                                eachRestriction += '</div>';
                                list_restriction.find('#' + keyParentRestriction).find('.item_restriction').prepend(eachRestriction);
                            });
                        });
                    }

                    restriction();

                } else {
                    let divRestriction = modal.find('#wrapper_restriction');

                    if (!Array.isArray(listRestriction)) {
                        $.each(listRestriction, function (keyParentRestriction, valueParentRestriction) {
                            //xóa hết element trước khi append cái mới vào
                            //tránh case lắy detail xong tắt modal xong lại ấn detail , ko bị nhân nhiều lần dữ liệu
                            divRestriction.find('ul li#' + keyParentRestriction + ' .each_restriction').empty();
                            $.each(valueParentRestriction, function (key, value) {
                                let eachRestriction = '<p class="m-0"> - ' + value + '</p>';
                                divRestriction.find('ul li#' + keyParentRestriction + ' .each_restriction').prepend(eachRestriction);
                            });
                        });
                    }

                    //gắn link cho nút active
                    let urlAction = element.attr('data-url-reset-key');

                    $(document).on('click', '.reset_key_from_modal', function (e) {
                        let modalAction = $('#modal_reset_key_credential');
                        modalAction.find('form').attr('action', urlAction);
                        modalAction.modal('show');
                    });
                }

                break;
        }

    }

    function restriction() {
        const list_restriction = $('#list_restriction');

        //ẩn các nội dung trong list restriction + bỏ tích các radio button
        list_restriction.find('.title_restriction .input_radio_restriction').prop("checked", false);
        list_restriction.find('.item_restriction').hide();

        $('.label_restriction').click(function () {
            list_restriction.find('.title_restriction .input_radio_restriction').prop("checked", false);
            list_restriction.find('.item_restriction').hide();

            let wrapperTypeRestriction = $(this).parents('.wrapper_type_restriction');
            wrapperTypeRestriction.find('.title_restriction .input_radio_restriction').prop("checked", true);

            // ẩn các item_restriction khác và hiện cái của radio button vừa tích
            wrapperTypeRestriction.find('.item_restriction').show();

            // hiện lại nút add item
            wrapperTypeRestriction.find('.item_restriction .btn_add_more_item').show();
        });

        // ấn vào add item
        list_restriction.on("click", ".btn_add_more_item", function () {
            let typeRestriction = $(this).parents('.wrapper_type_restriction').attr('id');

            let htmlAddMoreRestriction = '<div class="item_each"><input type="text" class="input_more_item" name=' + typeRestriction + '[]></div>';
            // htmlAddMoreRestriction += '<input type="hidden" name="type" value="' + typeRestriction + '">';

            let parentMoreRestriction = $(this).parents('.item_restriction').find('.wrapper_input_more_item');
            parentMoreRestriction.append(htmlAddMoreRestriction);
            parentMoreRestriction.find('.input_more_item').focus();

        });

        list_restriction.on("blur", ".input_more_item", function () {
            let valueInputMoreItem = $(this).val();

            if (valueInputMoreItem !== '') {
                let nameInputMoreItem = $(this).attr('name');
                let htmlInputMoreItem = '<div class="item_each">' + valueInputMoreItem;
                htmlInputMoreItem += '<input type="hidden" value="' + valueInputMoreItem + '" name="' + nameInputMoreItem + '" >';
                htmlInputMoreItem += '<span class="remove_restriction pull-right"><i class="fa fa-lg fa-times-circle" aria-hidden="true"></i></span>';
                htmlInputMoreItem += '</div>';
                $(this).parents('.wrapper_input_more_item').append(htmlInputMoreItem);
            }

            $(this).parents('.item_each').remove();
        });

        list_restriction.on("click", ".remove_restriction", function () {
            $(this).parents('.item_each').remove();
        });
    }

    function handleOtherAction(action, element, prefix, modal, response, update) {
    }

    $('#btn_create_credential').click(function () {
        $('#modal_create_credential').modal('show');
        restriction();
    });

    $('.favorite').click(function (event) {

        //add and remove favorite
        event.preventDefault();
        let id = $(this).attr('data-id');
        $('#show-loading').addClass('show-loading-page');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url: '/services/favorite/' + id,
            type: 'POST',
            dataType: 'json',
            success: function (response) {
                if (response.code) {
                    window.location.reload()
                }
            },
            error: function(error) {
                handlingModal();
            }
        });

    });

    $('body').on('click', '#modal_detail .search-email-user, #modal_edit .search-email-user', function(event) {
        event.preventDefault();
        let key = $(this).parent().find('#search-email-user').val();
        let listUserOfService = listUserCustom.filter(function(item) {
            return item.email.includes(key);
        });
        let parent = $('.list_user_access_service');

        if (listUserOfService.length == 0) {
            $('.list_user_selected').html('').css('display', 'none');
            $('.custom-warning-search-email').html('Không tìm thấy thông tin với từ khóa : ' + key);
        } else {
            $('.custom-warning-search-email').html('');
            $('.list_user_selected').html('').css('display', 'block');
            $.each(listUserOfService, function (index, value) {
                let htmlListUserOfService = '<li>';
                htmlListUserOfService += ' <span class="span_email">' + value.email + '</span>';
                htmlListUserOfService += ' <input type="hidden" name="user_ids[]" value="' + value.id + '" class="input_id_user">';
                htmlListUserOfService += ' </li>';
                parent.find('ul.list_user_selected').append(htmlListUserOfService);
            });
        }
    })
}());
